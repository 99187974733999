import Swiper from "swiper";
import '../Slider/assets/style.scss';


window.slider = function slider(container = 'swiper-container' , view=1.5 , viewM=2.5, viewT=4.5, viewP=4, effect='slide', loop=false, touch=1) {
    return new Swiper('.' + container, {
        slidesPerView: view,
        effect:effect,
        loop:loop,
        noSwiping:true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            575: {
                slidesPerView:viewM ,
            },
            991: {
                slidesPerView:viewT ,
            },
            1100: {
                slidesPerView: viewP,
            }
        },
    });
};


window.certificatesSlider = function detailSlider(container = 'swiper-container') {
    return new Swiper('.' + container, {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
        },
        noSwiping:true,
        loopedSlides: 5, //looped slides should be the same
        effect:'fade',
        parallax: true,
        parallaxEl: {
            el: '.parallax-bg',
            value: '-23%'
        },
        touchRatio: 0,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        spaceBetween: 5,
        slidesPerView: 1,
        navigation: {
            nextEl: '.next-btn',
            prevEl: '.prev-btn',
        },
        thumbs: {
            swiper: galleryThumbs()
        }
    });
};

window.galleryThumbs  = function galleryThumbs (container = 'gallery-thumbs') {
    return new Swiper('.' + container, {
        spaceBetween: 5,
        slidesPerView: 4.5,
        breakpoints: {
            575: {
                slidesPerView: 8,
            },
        },
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        speed:600,
        slideToClickedSlide: true,
    });
};


